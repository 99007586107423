/* @flow */
import {
  observable,
  reaction,
  computed,
  decorate,
  flow
} from 'mobx'

export default class FranchiseStore {
  api
  userStore
  franchises = []
  isLoading = true

  constructor (api) {
    this.api = api
    this.loadFranchises()
  }

  loadFranchises = flow(function * () {
      this.isLoading = true
      const fetchedFranchises = yield this.api.fetchFranchises()
      fetchedFranchises
        .sort((x, y) => {
          return x.name.localeCompare(y.name)
        })
        .forEach((json) => this.updateFranchiseFromServer(json))
      
      this.isLoading = false
  })

  async getById (id: string) {
    return this.api.fetchFranchiseById(id)
  }

  updateFranchiseFromServer (json) {
    let franchise = this.franchises.find(franchise => franchise.id === json.id)
    if (!franchise) {
      franchise = new Franchise(this, json.id)
      this.franchises.push(franchise)
    }
    franchise.updateFromJson(json)
  }
}

decorate(FranchiseStore, {
  franchises: observable,
  isLoading: observable
})

export class Franchise {
  id = ''
  name = ''
  stations = []

  store = undefined

  constructor (store, id) {
    this.store = store
    this.id = id
  }

  updateFromJson (json) {
    this.id = json.id || ''
    this.name = json.name || ''
    this.stations = json.stations || []
  }

  get asJson () {
    return {
      id: this.id,
      name: this.name,
      stations: this.stations
    }
  }
}

decorate(Franchise, {
  asJson: computed
})
