/* @flow */
import {
  observable,
  computed,
  decorate,
  flow
} from 'mobx'

export default class RecentSongStore {
  api
  playlistStore = undefined
  recentSongs = []
  isLoading = true

  constructor (api, playlistStore) {
    this.api = api
    this.playlistStore = playlistStore
    this.loadRecentSongs()
  }

  loadRecentSongs = flow(function * (tags: string[] = []) {
    this.isLoading = true
    this.recentSongs.replace([])
    const selector = this.playlistStore.getTagSelector(tags)
    const fetchRecentSongs = yield this.api.listLatest(selector, 20)

    fetchRecentSongs
      .forEach((json) => this.updateRecentSongsFromServer(json))
    this.isLoading = false
  })

  updateRecentSongsFromServer (json) {
    let audioMessage = this.recentSongs.find(audioMessage => audioMessage.uuid === json.uuid)
    if (!audioMessage) {
      audioMessage = new RecentSong(this, json.uuid)
      this.recentSongs.push(audioMessage)
    }
    audioMessage.updateFromJson(json)
  }
}

decorate(RecentSongStore, {
  recentSongs: observable,
  isLoading: observable
})

export class RecentSong {
  uuid = ''
  created = new Date()
  artist = ''
  title = ''

  constructor (store, uuid) {
    this.uuid = uuid
  }

  updateFromJson (json) {
    this.uuid = json.uuid || ''
    this.artist = json.artist || ''
    this.title = json.title || ''
    this.created = new Date(json.created)
  }

  get asJson () {
    return {
      uuid: this.uuid,
      artist: this.artist,
      title: this.title,
      created: this.created
    }
  }
}

decorate(RecentSong, {
  enabled: observable,
  asJson: computed
})
