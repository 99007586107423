/* @flow */
import React, { PureComponent } from 'react'
import moment from 'moment'
import { Collapse, Button } from 'reactstrap'
import PlaceholderItems from '../PlaceholderItems'

import {
  AddSong,
  Star
} from '../Icons'
import './RecentlyAdded.css'


export type Song = {
  uuid: string,
  created: Date,
  artist: string,
  title: string
}

export type OwnProps = {
  recentlyAdded: Song[],
  channelName: string
}

export type DispatchProps = {
  onSelect: (track: Song) => void
}

export type Props = OwnProps & DispatchProps

export type State = {
  isOpen: boolean
}

const DEFAULT_COUNT = 5

export default class RecentlyAdded extends PureComponent<Props, State> {
  state: State = {
    isOpen: false
  }

  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  renderItem (song: Song, onSelect: (uuid: string) => void) {
    const artist = song.artist || ''
    const title = song.title || ''
    const uuid = song.uuid
    const isCreatedMoreThan5DaysAgo = moment().diff(song.created, 'days') >= 5

    // Show a week ago for all songs older than 5 days
    const createdStr = isCreatedMoreThan5DaysAgo
      ? 'a week ago'
      : moment(song.created).fromNow()

    return (
      <li
        key={ uuid }
        title={ `${artist} - ${title}`}
        className="recently-added__item"
        onClick={ () => { onSelect(song) }}
      >
        <div className="recently-added__item-content">
          <AddSong />
          <strong className="recently-added__item-artist">{ artist.toUpperCase() }</strong> - { title }
        </div>
        <div className="recently-added__item-time">
          { createdStr }
        </div>
      </li>
    )
  }

  render () {
    const { recentlyAdded, onSelect, channelName } = this.props
    return (
      <div className="recently-added">
        <h2 className="recently-added__title mb-3" ><Star /> Recently added to <strong>{ channelName }</strong></h2>
          {
            recentlyAdded.length === 0 && (
              <div className="recently-added__placeholder">
                <PlaceholderItems count={ DEFAULT_COUNT } />
              </div>
            )
          }
          {
            recentlyAdded.length > 0 && (
              <ul className="recently-added__list recently-added__list-short">
                {
                  recentlyAdded.slice(0, DEFAULT_COUNT).map((track) => {
                    return this.renderItem(track, onSelect)
                  })
                }
              </ul>
            )
          }
          <ul  className="recently-added__list">
            <Collapse isOpen={ this.state.isOpen }>
              {
                recentlyAdded.slice(5).map((track) => {
                  return this.renderItem(track, onSelect)
                })
              }
            </Collapse>
            <div>
              <a
                className="recently-added__show-more"
                onClick={ this.toggle }
              >
                {
                  !this.state.isOpen && (
                    <span>Show more</span>
                  )
                }
                {
                  this.state.isOpen && (
                    <span>Show less</span>
                  )
                }
              </a>
            </div>
          </ul>
      </div>
    )
  }
}
