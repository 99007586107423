import 'react-app-polyfill/ie11'
import React from 'react'
import './styles/bootstrap.scss'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

serviceWorker.register()
