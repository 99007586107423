import React from 'react'
import cx from 'classnames'
import './Placeholders.css'

export const TextRow = ({ width, className }) => (
  <div style={ { width } } className={ cx('placeholder__text-row', className) } />
)

TextRow.defaultProps = {
  width: '100%'
}

export const Circle = ({ className }) => (
  <div className={ cx('placeholder__circle', className) } />
)

export const Line = ({ width, className }) => (
  <div style={ { width } } className={ cx('placeholder__line', className) } />
)

Line.defaultProps = {
  width: '100%'
}
