/* @flow */
import React, { Component } from 'react'
import moment from 'moment'
import {
  Button
} from 'reactstrap'
import './ProButton.css'

export type OwnProps = {
  isPremium: boolean,
  isTrial: boolean,
  gymName: string,
  franchiseName: string,
  premiumUntil: number
}

export type DispatchProps = {
  getPro: () => void
}

export type Props = OwnProps & DispatchProps

export default class ProButton extends Component<Props, { isOpen: boolean }> {
  render () {
    const { isPremium, isTrial, premiumUntil, gymName, getPro, franchiseName } = this.props

    const isInTrialPeriod = isTrial && isPremium
    const trialPeriodExpired = isTrial && !isPremium
    const subscriptionExpired = !isTrial && !isPremium
    const isInPremiumPeriod = !isTrial && isPremium

    if (isInPremiumPeriod) {
      const name = (franchiseName)

      return (
        <div className="header-pro header-pro--is-pro">
          <div className="header-pro__action-container">
              <strong className="header-pro__action">
                { gymName } { 
                  franchiseName && (
                    <div>
                      <small>{ franchiseName }</small>
                    </div>
                  )
                }
              </strong>
              <span className="header-pro__badge">Pro</span>
          </div>
        </div>
      )
    }

    const now = Date.now()
    const showCountdown = premiumUntil > now
    const isLessThanDayLeft = premiumUntil - 24 * 60 * 60 * 1000 <= now

    return (
      <div className="header-pro">
        <Button
          color="primary"
          onClick={ () => { getPro() } }
        >
          Get <strong>Pro</strong>
        </Button>
        <div className="header-pro__actions">
          { trialPeriodExpired && (
              <div className="header-pro__action-container">
                <div>
                  <strong className="header-pro__action">Get all pro</strong>
                  features
                </div>
              </div>
            )
          }
          { isInTrialPeriod && showCountdown && !isLessThanDayLeft && (
              <div className="header-pro__action-container">
                <div>

                  <strong className="header-pro__action">{ moment(premiumUntil).fromNow(true) }</strong>
                  remaining in your trial
                </div>
              </div>
            )
          }
          { isInTrialPeriod && showCountdown && isLessThanDayLeft && (
              <div className="header-pro__action-container">
                <div>
                  <strong className="header-pro__action">Last day</strong>
                  of the trial left
                </div>
              </div>
            )
          }
          { isInTrialPeriod && !showCountdown && (
              <div className="header-pro__action-container">
                <div>
                  <strong className="header-pro__action">Trial</strong>
                  ending soon
                </div>
              </div>
            )
          }
          { subscriptionExpired && (
              <div className="header-pro__action-container">
                <div>
                  <strong className="header-pro__action">Get all Pro</strong>
                  features again
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}
