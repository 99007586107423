/* @flow */
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from 'reactstrap'
import Quotes from '../Quotes'

import {
  Close,
  SmallCheck
} from '../Icons'

import './Modal.css'

const getPros = () => {
  return (
    <div className="modal__content">
      <ul>
        <li><SmallCheck /> <span className="modal__list_content">All music stations</span></li>
        <li><SmallCheck /> <span className="modal__list_content">Daily music updates</span></li>
        <li><SmallCheck /> <span className="modal__list_content">Audio promotions to boost sales</span></li>
        <li><SmallCheck /> <span className="modal__list_content">Legal and compliant music</span></li>
      </ul>
      <ul>
        <li><SmallCheck /> <span className="modal__list_content">High-quality curated music</span></li>
        <li><SmallCheck /> <span className="modal__list_content">Explicit and Soft-explicit filters</span></li>
        <li><SmallCheck /> <span className="modal__list_content">Dislike song button</span></li>
        <li><SmallCheck /> <span className="modal__list_content">Branded motivational quotes to keep members motivated</span></li>
      </ul>
    </div>
  )
}

export const AudioMessageModal = observer(class AudioMessageModal extends Component {
  render () {
    const {
      isOpen,
      quotes,
      onMotivationalMessagesStatusChanged,
      onPromotionalMessagesStatusChanged,
      onCancel
    } = this.props
    return (
      <div>
        <Modal isOpen={ isOpen } toggle={ onCancel } className={this.props.className}>
          <div onClick={ onCancel } className="modal__close">
            <Close />
          </div>
          <ModalHeader>
            Audio Promotions
          </ModalHeader>
          <ModalBody>
            <Quotes
              quotes={ quotes }
              onMotivationalMessagesStatusChanged={ onMotivationalMessagesStatusChanged }
              onPromotionalMessagesStatusChanged={ onPromotionalMessagesStatusChanged }
              onToggle={ this.onToggle }
            />
          </ModalBody>
        </Modal>
      </div>
    )
  }
})

export const SignUpModal = inject((stores) => ({
  userStore: stores.userStore
}))(
  observer(class SignUpModal extends Component {
    constructor (props) {
      super(props)
      this.login = this.login.bind(this)
      this.signUp = this.signUp.bind(this)
    }

    login () {
      this.props.userStore.login()
    }

    signUp () {
      this.props.userStore.login('', true)
    }

    render() {
      const {
        isOpen,
        onCancel
      } = this.props
      return (
        <div>
          <Modal isOpen={ isOpen } toggle={ onCancel } className={this.props.className}>
            <div onClick={ onCancel } className="modal__close">
              <Close />
            </div>
            <ModalHeader>
              Signup and try Pro<strong>14 days for free</strong>
            </ModalHeader>
            <ModalBody>
              { getPros() }
              <div className="modal_buttonGroup">
                <Button color="primary" onClick={ this.signUp } size="lg">
                  <strong>Sign up</strong>
                </Button>
                <div onClick={ this.login } className="btn__link">
                  Login
                </div>
              </div>
              <p>You can continue to use the Free version after the 14 days, no credit card required.
              </p>
              <p>
                By signing up, you agree to <a
                  href="http://gymradio.com/termsofuse.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >Terms of Service</a>
              </p>
            </ModalBody>
          </Modal>
        </div>
      )
    }
  })
)

export class PremiumModal extends Component {
  render() {
    const {
      isOpen,
      onSubmit,
      onCancel
    } = this.props
    return (
      <div>
        <Modal isOpen={ isOpen } toggle={ onCancel } className={this.props.className}>
          <div onClick={ onCancel } className="modal__close">
            <Close />
          </div>
          <ModalHeader>
            <strong>Upgrade to Pro</strong> and use all features
          </ModalHeader>
          <ModalBody>
            { getPros() }
            <div className="modal_buttonGroup">
              <Button color="primary" onClick={ onSubmit } size="lg">
                <strong>See all plans</strong>
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export class FreeSlotModal extends Component {
  render() {
    const {
      isOpen,
      onSubmit,
      onCancel
    } = this.props
    return (
      <div>
        <Modal isOpen={ isOpen } toggle={ onCancel } className={this.props.className}>
          <div onClick={ onCancel } className="modal__close">
            <Close />
          </div>
          <ModalHeader>
            Break the free limits
          </ModalHeader>
          <ModalBody>
            <p>
              Too many gyms are using the limited free channel right now. Press the play button again in a minute to start the music again or upgrade to Pro and never experience the awkward silence at your gym again.
            </p>
            <div className="modal_buttonGroup">
              <Button color="primary" onClick={ onSubmit } size="lg">
                <strong>Upgrade to Pro</strong>
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
