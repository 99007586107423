/* @flow */
import React from 'react'

import {
  Play,
  Pause,
  Dislike,
  Next
} from '../Icons'
import './PlayerControls.css'

export type OwnProps = {
  playing: boolean
}

export type DispatchProps = {
  onSkip: () => void,
  onDislike: () => void,
  playPause: () => void
}

export type Props = OwnProps & DispatchProps

export default ({ playing, activeChannel, onPlayPause, onSkip, onDislike }: Props) => {
  return (
    <ul className="player__controls">
      <li>
        <button type="button" className="player__controls__btn" onClick={ onDislike }>
          <Dislike />
        </button>
      </li>
      <li>
        <button type="button" className="player__controls__btn player__controls__btn--play" onClick={ onPlayPause }>
          { !playing &&
            (
              <i className="icon icon__play">
                <Play />
              </i>
            )
          }
          { playing &&
            (
              <i className="icon icon__pause">
                <Pause />
              </i>
            )
          }
        </button>
      </li>
      <li>
        <button type="button" className="player__controls__btn" onClick={ onSkip }>
          <i className="icon icon__next">
            <Next />
          </i>
        </button>
      </li>
    </ul>
  )
}
