/* @flow */
import React from 'react'
import Slider from 'rc-slider'

import {
  Mute,
  Loud
} from '../Icons'

import 'rc-slider/assets/index.css'
import './Volume.css'

export type OwnProps = {
  step: number,
  theme: 'light' | 'dark',
  defaultValue: number,
  value: number
}

export type DispatchProps = {
  onChange: (volume: number) => void,
  onMute: () => void,
  onLoud: () => void
}

export type Props = OwnProps & DispatchProps

const Volume = ({ step, theme, value, defaultValue, onChange, onMute, onLoud }: Props) => {
  return (
    <div className={ `volume volume--${theme}` }>
      <span onClick={ onMute } className="volume__btn volume__btn--mute">
        <Mute />
      </span>
      <Slider
        step={ step }
        defaultValue={ defaultValue }
        value={ value }
        onChange={ onChange }
      />
      <span onClick={ onLoud } className="volume__btn volume__btn--loud">
        <Loud />
      </span>
    </div>
  )
}

Volume.defaultProps = {
  step: 10,
  defaultValue: 50,
  theme: 'dark'
}

export default Volume
