/* @flow */
import {
  observable,
  action,
  computed,
  decorate,
  flow
} from 'mobx'
import stringSimilarity from 'string-similarity'
import { createMachine, interpret } from 'xstate'
import { TOPSONG_COUNT, PRELOAD_TRACKS_COUNT, PROMOTION_COUNT, SONG_COUNT, defaultPlaylistStates } from './playlist.config'

type PlaylistSchemaType = 'promotion' | 'message' | 'motivation' | 'topsong' | 'song'

export default class PlaylistStore {
  api
  userStore
  tracks = []
  userStore
  audioMessageStore
  isLoading = true
  playlistSchema = []
  counter = 0
  playlistService = null

  constructor (api, userStore, audioMessageStore) {
    this.api = api
    this.userStore = userStore
    this.audioMessageStore = audioMessageStore
  }

  get currentTrack () {
    return (this.tracks.length > 0 && this.tracks[0]) || {}
  }

  get maxTrackCount () {
    return this.playlistSchema.length
  }

  get position () {
    return this.counter % this.maxTrackCount
  }

  set position (position: number) {
    this.counter = position
  }

  get upcomingPromotionalMessages () {
    return this.tracks.filter((track) => {
      return track.type === 'message'
    })
  }

  get promotionalMessagesEnabled () {
    const { isPremium } = this.userStore
    const { enabledCount } = this.audioMessageStore
    return isPremium && enabledCount > 0
  }

  get adsEnabled () {
    const { isPremium } = this.userStore
    return !isPremium
  }

  get motivationalMessagesEnabled () {
    const { disableMotivationQuotes } = this.userStore
    return !disableMotivationQuotes
  }

  get explicitFilter () {
    const { explicitFilter } = this.userStore
    return explicitFilter
  }

  getTagSelector (tags: string[]) {
    let explicitTags = tags
    switch (this.explicitFilter) {
      case 'soft':
        explicitTags = tags.map((tag) => {
          return [
            `${tag},softexplicit`,
            `${tag},clean`
          ]
        })
        break
      case 'clean':
        explicitTags = tags.map((tag) => {
          return `${tag},clean`
        })
        break
    }
    return explicitTags
  }

  dislikeSong (uuid) {
    const { id: userId } = this.userStore
    if (userId) {
      this.api.dislikeSong(userId, uuid)
    }
  }

  loadPlaylist = flow(function * (tags: string[], playing: boolean) {
    const { id: userId, franchiseId, isPremium } = this.userStore

    this.isLoading = true

    // Keep current track if playing
    const shouldKeepPlayingSong = playing && this.tracks.length > 0
    if (shouldKeepPlayingSong) {
      this.tracks.replace([...this.tracks.slice(0, 1)])
      this.position = 1
    } else {
      this.tracks.replace([])
      this.position = 0
    }

    const messages = yield this._getPromotionalMessages(userId, franchiseId, PROMOTION_COUNT, isPremium)
    const promotionalMessages = this._fillArray(
      yield this.api.listRandom(this.getTagSelector(tags), userId, 'promotion', PROMOTION_COUNT, isPremium),
    PROMOTION_COUNT)
    const motivationalMessages = this._fillArray(
      yield this.api.listRandom([], userId, 'motivation', PROMOTION_COUNT, isPremium),
    PROMOTION_COUNT)

    const topSongs = yield this.api.listRandom(this.getTagSelector(tags), userId, 'topsong', TOPSONG_COUNT, isPremium)
    const songs = yield this.api.listRandom(this.getTagSelector(tags), userId, 'song', SONG_COUNT, isPremium)

    // Compute 
    const messagesEnabled = messages.length > 0
    const promotionalMessagesEnabled = promotionalMessages.length > 0
    const motivationalMessagesEnabled = this.motivationalMessagesEnabled && motivationalMessages.length > 0
    const topSongsEnabled = topSongs.length > 0

    // Create playlist
    const playlistSchema = yield this._buildPlaylistSchema(
      messagesEnabled,
      promotionalMessagesEnabled,
      motivationalMessagesEnabled,
      topSongsEnabled
    )

    this.playlistSchema = playlistSchema

    for (let i = this.position; i < playlistSchema.length; i++) {
      if (playlistSchema[i]) {
        const type: PlaylistSchemaType = playlistSchema[i]
        switch (type) {
          case 'message':
            yield this._addPromotion(messages.shift(), userId, franchiseId, isPremium)
            break
          case 'promotion':
            yield this._add(promotionalMessages.shift(), false, isPremium)
            break
          case 'motivation':
            yield this._addPromotion(motivationalMessages.shift(), userId, franchiseId, isPremium)
            break
          case 'topsong':
            yield this._add(topSongs.shift(), false, isPremium)
            break
          default:
            yield this._add(songs.shift(), false, isPremium)
            break
        }
      }
    }

    this.isLoading = false
  })

  removeTrack = flow(function * (track, tags) {
    const trackIndex = this.tracks
      .findIndex((upcomingTrack) => {
        return upcomingTrack.id === track.id
      })

    if (trackIndex >= 0) {
      this.tracks.splice(trackIndex, 1) // remove element
      yield this.addTracks(this.getTagSelector(tags), 1)
    }
  })

  addTracks = flow(function * (tags, count) {
    const { id: userId, franchiseId, isPremium } = this.userStore
    const MAX_RETRIES = 5

    for (let i = 0; i < count; i++) {
      if (this.playlistSchema[this.position]) {
        const trackType: PlaylistSchemaType = this.playlistSchema[this.position]
        let newTracks = []
        switch (trackType) {
          case 'message':
            newTracks = yield this._getPromotionalMessages(userId, franchiseId, MAX_RETRIES, isPremium)
            break
          case 'promotion':
            newTracks = yield this.api.listRandom([], userId, 'promotion', MAX_RETRIES, isPremium)
            break
          case 'motivation':
            newTracks = yield this.api.listRandom([], userId, 'motivation', MAX_RETRIES, isPremium)
            break
          case 'topsong':
            newTracks = yield this.api.listRandom(tags, userId, 'topsong', MAX_RETRIES, isPremium)
            break
          default:
            newTracks = yield this.api.listRandom(tags, userId, 'song', MAX_RETRIES, isPremium)
            break
        }

        // Try to fetch songs as a fallback
        if (newTracks.length === 0) {
          newTracks = yield this.api.listRandom(this.getTagSelector(tags), userId, 'song', MAX_RETRIES, isPremium)
        }

        // Throw exception if offline or api down
        if (newTracks.length === 0) {
          throw new Error('Cannot add any tracks')
        }

        // Try MAX_RETRIES times get track, that is not in the playlist
        for (let j = 0; j < newTracks.length; j++) {
          const newTrack = newTracks[j]
          // Is fetched track in playlist?
          const isTrackInUpcoming = this.tracks
            .filter((track) => {
              // Check uuid
              const containUuid = newTrack.uuid === track.uuid
              if (containUuid) {
                return true
              }

              // Check word similarity
              const similarity = stringSimilarity.compareTwoStrings(track.fullName, newTrack.fullName)

              const isSimilar = similarity >= 0.8
              return isSimilar
            }).length > 0

          if (isTrackInUpcoming && j < newTracks.length - 1) {
            // Track is in the playlist => continue
            continue
          }

          yield this._addPromotion(newTrack, userId, franchiseId, isPremium)
          this.counter++
          break // Track was added => break
        }
      }
    }
  })

  _isNextTrackLoading = false
  nextTrack = flow(function * (tags: string[]) {
    const { isPremium } = this.userStore
    const removedSong = this.tracks.shift()

    if (this._isNextTrackInProgress) {
      return
    }

    // Add at least one new track
    const count = Math.max(
      1,
      PRELOAD_TRACKS_COUNT - this.tracks.length
    )
    this._isNextTrackInProgress = true
    try {
      yield this.addTracks(this.getTagSelector(tags), count)
    } catch (err) {
      // Could not push new track
      // Add it after last preloaded track
      // Available only for premium users
      if (isPremium) {
        const index = this.tracks.findIndex((track) => track.loaded === false)
        this.tracks.splice(index, 0, removedSong)
      }
    } finally {
      // This is not necessary, but I want to be 100% sure, that _isNextTrackInProgress will be false
      this._isNextTrackInProgress = false
    }

    this._isNextTrackInProgress = false
    return
  })

  addTrackByUuid = flow(function * (uuid: string, next = false) {
    const { isPremium } = this.userStore
    const track = yield this.api.getByUuid(uuid, isPremium)

    if (track) {
      this._add(track, next, isPremium)
    }
  })

  // Use promotional messages for premium users, use ads for free users
  async _getPromotionalMessages (userId: string, franchiseId: string, count: number, isPremium = true) {
    let promotionalMessages = []
    if (this.promotionalMessagesEnabled) {
      promotionalMessages = this._fillArray(
        await this.api.fetchRandomAudioMessages(userId, franchiseId, count, isPremium), count
      )
    } else if (this.adsEnabled) {
      promotionalMessages = this._fillArray(
        await this.api.listRandom(['gymradioads'], userId, 'promotion', count, isPremium), count
      )
    }
    return promotionalMessages
  }

  async _buildPlaylistSchema (messagesEnabled, promotionalMessagesEnabled, motivationalMessagesEnabled, topSongsEnabled) {
    const states = defaultPlaylistStates
    const context = {
      topSongsEnabled,
      promotionalMessagesEnabled,
      motivationalMessagesEnabled,
      messagesEnabled
    }

    const playlistMachine = createMachine({
      id: 'playlist',
      initial: 'start',
      context,
      states,
    }, {
      guards: {
        topSongsEnabled: (ctx) => {
          return ctx.topSongsEnabled
        },
        promotionalMessagesEnabled: (ctx) => {
          return ctx.promotionalMessagesEnabled
        },
        motivationalMessagesEnabled: (ctx) => {
          return ctx.motivationalMessagesEnabled
        },
        messagesEnabled: (ctx) => {
          return ctx.messagesEnabled
        }
      },
    })

    this.playlistService = interpret(playlistMachine).start()

    const playlistSchema: Array<PlaylistSchemaType> = []

    // Get first element
    const state = this.playlistService.initialState
    const { type } = this._extractMetadata(state)
    playlistSchema.push(type)

    for (var i = 0; i < PRELOAD_TRACKS_COUNT; i++) {
      const nextState = this.playlistService.send({ type: 'NEXT' })
      const state = nextState
      const { type } = this._extractMetadata(state)

      if (type) {
        playlistSchema.push(type)
      }
    }

    const playlistSchemaPart = [...playlistSchema]
    while (playlistSchema.length > 0 && (playlistSchema.length + playlistSchemaPart.length) <= PRELOAD_TRACKS_COUNT) {
      playlistSchema.push(...playlistSchemaPart)
    }

    return playlistSchema
  }

  _extractMetadata (state: any) {
    return state?.meta[Object.keys(state?.meta)[0]] || {}
  }

  _fillArray (items: Array<any>, count: number) {
    const length = items.length
    const filledItems = [...items]
    if (length < count && length > 0) {
      for (let i = length; i < count; i++) {
        const randomItem = items[Math.floor(Math.random() * length)] // get random item from array
        filledItems.push(randomItem)
      }
    }

    return filledItems
  }

  _addPromotion = flow(function * (track, userId, franchiseId, isPremium) {
    if (!track) {
      return
    }

    yield this._add(track, false, isPremium)

    const isPromotionalMessage =  (
      track.type === 'message' &&
      track.tags.indexOf('businessmessages') >= 0
    )
    const isMotivationalMessage = (
      track.type === 'motivation'
    )
    const shouldAddGymName = isPromotionalMessage || isMotivationalMessage

    if (shouldAddGymName) {
      const {
        title,
        artist
      } = track
      const promotions = yield this.api.fetchGymName(userId, franchiseId, isPremium)

      if (promotions.length > 0) {
        const promotion = promotions[0]

        // Do not add gymradio name to promotional messages
        const isGymRadioName = promotion.tags.indexOf('gymradio') >= 0

        if (isPromotionalMessage && isGymRadioName) {
          return
        }

        const viewType = `${promotion.type}-${track.type}`
        yield this._add({
          ...promotion,
          viewType,
          title,
          artist
        }, false, isPremium)
      }
    }
  })

  _add (json, next = false, isPremium = true) {
    if (!json) {
      return
    }
    let track = new Track(this, json.uuid)
    track.updateFromJson(json)

    if (!next) {
      this.tracks.push(track)
    } else {
      // Add as next playing song

      // If track on index 1 is type === gymname,
      // insert on the next position
      const index = (this.tracks[1] && this.tracks[1].type === 'gymname') ? 2 : 1
      this.tracks.splice(index, 0, track)
    }
  }
}

decorate(PlaylistStore, {
  tracks: observable,
  promotionalMessagesEnabled: computed,
  motivationalMessagesEnabled: computed,
  adsEnabled: computed,
  upcomingPromotionalMessages: computed,
  currentTrack: computed,
  _add: action,
  isLoading: observable
})

export class Track {
  uuid = ''
  artist = ''
  title = ''
  url = ''
  type = ''
  viewType = ''
  id = ''
  gaiaId: ''
  isrc: ''
  preloaded = false
  downloaded = false

  store = undefined

  constructor (store, uuid) {
    this.store = store
    this.uuid = uuid
  }

  updateFromJson (json) {
    this.uuid = json.uuid || ''
    this.artist = json.artist || ''
    this.title = json.title || ''
    this.type = json.type || ''
    this.gaiaId = json.gaiaId || ''
    this.isrc = json.isrc || ''
    this.viewType = json.viewType || json.type || ''
    this.url = json.url || ''
    this.id = `${json.uuid}${Date.now()}${Math.random()}`
  }

  get fullName () {
    return `${this.artist} - ${this.title}`
  }

  get asJson () {
    return {
      uuid: this.uuid,
      url: this.url,
      artist: this.artist,
      title: this.title,
      type: this.type,
      viewType: this.viewType
    }
  }
}

decorate(Track, {
  uuid: observable,
  url: observable,
  artist: observable,
  title: observable,
  type: observable,
  id: observable,
  preloaded: observable,
  downloaded: observable,
  fullName: computed,
  asJson: computed
})
