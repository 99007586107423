/* @flow */
const eventName = 'sessionupdated'
let timeout
let callbacks = []

const dispatchStorageEvent = () => {
  // Debounce storage event
  if (timeout) {
    clearTimeout(timeout)
  }
  timeout = setTimeout(() => {
    window.dispatchEvent(new Event(eventName))
  }, 10)
}

export const get = (key: string) => {
  return localStorage.getItem(key)
}

export const set = (key: string, value: any) => {
  localStorage.setItem(key, value)
  dispatchStorageEvent()
  return
}

export const remove = (key: string) => {
  localStorage.removeItem(key)
  dispatchStorageEvent()
  return
}

export const watch = (cb: () => void) => {
  callbacks.push(cb)
  window.addEventListener(eventName, cb)
}

export const unwatch = () => {
  callbacks.forEach((cb) => {
    window.removeEventListener(eventName, cb)
  })
}
