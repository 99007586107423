/* @flow */
let configFromWindowObject = {}
try {
  configFromWindowObject = JSON.parse(window.GR_CONFIG)
} catch (err) { }

const getValue = (key: string) => {
  return configFromWindowObject[key] || process.env[key] || ''
}

const config = {
  INTERCOM_APP_ID: getValue('REACT_APP_INTERCOM_APP_ID'),
  MIXPANEL_TOKEN: getValue('REACT_APP_MIXPANEL_TOKEN'),
  AUTH0_CUSTOM_DOMAIN: getValue('REACT_APP_AUTH0_CUSTOM_DOMAIN'),
  AUTH0_DOMAIN: getValue('REACT_APP_AUTH0_DOMAIN'),
  AUTH0_CLIENT_ID: getValue('REACT_APP_AUTH0_CLIENT_ID'),
  AUTH0_CALLBACK_URL: getValue('REACT_APP_AUTH0_CALLBACK_URL'),
  BASE_URL_RECURLY: getValue('REACT_APP_BASE_URL_RECURLY'),
  BASE_URL_WEB: getValue('REACT_APP_BASE_URL_WEB'),
  BASE_URL_API: getValue('REACT_APP_BASE_URL_API'),
  BASE_URL_EVENT_API: getValue('REACT_APP_BASE_URL_EVENT_API')
}

export default config
