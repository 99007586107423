/* @flow */
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { TextRow, Circle, Line } from '../Placeholders'

type OwnProps = {
  count: number
}

class PlaceholderUpcoming extends Component<OwnProps> {
  render () {
    const { count } = this.props

    return (
      <div className="placeholder-upcoming">
        <Line />
        {
          Array(count).fill('').map((_, i) => {
            const width = Math.random() * 50 + 30
            return (
              <div key={ i }>
                <Circle />
                <TextRow width={ `${width}%` }/>
                <Line />
              </div>
            )
          })
        }
      </div>
    )
  }
}

PlaceholderUpcoming.defaultProps = {
  count: 20
}

export default observer(PlaceholderUpcoming)