/* @flow */
import { observable, computed, decorate, flow, action } from "mobx";
import { fromPromise } from "mobx-utils";
import auth0 from "auth0-js";
import { serializable } from "serializr";
import { persist } from "mobx-persist";
import config from "../config";
import * as store from "../Store";

const AUTH0_NAMESPACE = "https://gymradio.com/";

const ExplicitFilterEnum = {
  clean: "clean",
  soft: "soft",
  explicit: "explicit",
};

type ExplicitFilter = $Keys<typeof ExplicitFilterEnum>;

const DEFAULT_EXPLICIT_FILTER: ExplicitFilter = "explicit";

export default class UserStore {
  tokenRenewalTimeout;
  store;
  franchiseStore;

  auth0 = new auth0.WebAuth({
    domain: config.AUTH0_CUSTOM_DOMAIN,
    clientID: config.AUTH0_CLIENT_ID,
    redirectUri: config.AUTH0_CALLBACK_URL,
    configurationBaseUrl: `https://${config.AUTH0_DOMAIN}`,
    audience: `https://${config.AUTH0_DOMAIN}/api/v2/`,
    responseType: "token id_token",
    scope:
      "openid profile email user_id id read:current_user update:current_user_metadata",
  });

  // Auth
  accessToken = "";
  idToken = "";
  expiresAt = 0;
  redirectUrl = "/";

  // User
  id = "";
  gymName = "";
  email = "";
  gaiaToken = "";
  franchiseId = "";

  // User state
  isLoading = false;
  isLoggedIn = false;
  isEmailVerified = false;
  isSubscribed = false;
  premiumUntil = 0;
  isBlocked = false;
  blockReason = "";
  isTrial = false;
  limitFreeStation = false;
  explicitFilter: ExplicitFilter = DEFAULT_EXPLICIT_FILTER;
  disableMotivationQuotes = false;

  constructor(franchiseStore) {
    this.store = store;
    this.franchiseStore = franchiseStore;
  }

  forcePaid = action(function () {
    const renewInterval = 60 * 1000;
    this.isSubscribed = true;
    this.isTrial = false;

    setTimeout(() => {
      this.renewToken();
    }, renewInterval);
  });

  login = flow(function* (
    redirectUrl: string,
    shouldSignUp: boolean = false,
    franchiseId: string = ""
  ) {
    if (redirectUrl) {
      this.redirectUrl = redirectUrl;
    }
    let config = {
      initial_screen: shouldSignUp ? "signUp" : "login",
      mode: shouldSignUp ? "signUp" : "login",
      screen_hint: shouldSignUp ? "signUp" : undefined,
    };
    if (franchiseId) {
      config.franchise_id = franchiseId;
    }

    this.auth0.authorize(config);
  });

  signUp = flow(function* (
    redirectUrl: string,
    shouldSignUp: boolean = false,
    franchiseId: string = ""
  ) {
    if (redirectUrl) {
      this.redirectUrl = redirectUrl;
    }
    let config = {
      screen_hint: "signup",
    };
    if (franchiseId) {
      config.franchise_id = franchiseId;
    }

    this.auth0.authorize(config);
  });

  setEnableMotivationQuotes = flow(function* (enabled: boolean) {
    const auth0Manage = new auth0.Management({
      domain: config.AUTH0_CUSTOM_DOMAIN,
      token: this.accessToken,
    });
    yield new Promise((resolve, reject) => {
      auth0Manage.patchUserMetadata(
        this.id,
        {
          disable_motivation_quotes: !enabled,
        },
        (err) => {
          if (err) {
            return reject(err);
          }
          resolve();
        }
      );
    });
    this.disableMotivationQuotes = !enabled;
  });

  setExplicitFilter = flow(function* (explicitFilter: ExplicitFilter) {
    const isValueValid = !!ExplicitFilterEnum[explicitFilter];
    if (!isValueValid) {
      this.explicitFilter = DEFAULT_EXPLICIT_FILTER;
      return;
    }
    const auth0Manage = new auth0.Management({
      domain: config.AUTH0_CUSTOM_DOMAIN,
      token: this.accessToken,
    });
    yield new Promise((resolve, reject) => {
      auth0Manage.patchUserMetadata(
        this.id,
        {
          explicit_filter: explicitFilter,
        },
        (err) => {
          if (err) {
            return reject(err);
          }
          resolve();
        }
      );
    });
    this.explicitFilter = explicitFilter;
  });

  reset = action(function () {
    this.id = "";
    this.explicitFilter = DEFAULT_EXPLICIT_FILTER;
    this.gymName = "";
    this.gaiaToken = "";
    this.isLoggedIn = false;
    this.franchiseId = "";
    this.email = "";
    this.isEmailVerified = false;
    this.accessToken = "";
    this.premiumUntil = 0;
    this.expiresAt = 0;
    this.isSubscribed = false;
    this.isTrial = true;
    this.isBlocked = false;
    this.limitFreeStation = false;
    this.blockReason = "";
    this.disableMotivationQuotes = false;
  });

  logout = action(function () {
    this.reset();

    clearTimeout(this.tokenRenewalTimeout);
  });

  handleAuthentication = flow(function* () {
    return new Promise((resolve, reject) => {
      let cookie = {};
      document.cookie.split(";").forEach(function (el) {
        let [k, v] = el.split("=");
        cookie[k.trim()] = v;
      });

      const stateCreds = cookie["stateCreds"];

      const config = stateCreds ? JSON.parse(decodeURIComponent(stateCreds)) : {};

      this.auth0.parseHash(config, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          resolve(authResult);
        } else if (err) {
          console.error(
            `Error: ${err.error}. Check the console for further details.`
          );
          reject(err);
        }
      });
    });
  });

  get isPremium() {
    return (
      this.isSubscribed ||
      (!this.isSubscribed &&
        this.isTrial &&
        new Date().getTime() < this.premiumUntil)
    );
  }

  get franchise() {
    return this.observableFranchise.value;
  }

  get observableFranchise() {
    if (this.franchiseId) {
      return fromPromise(this.franchiseStore.getById(this.franchiseId));
    }
    return { value: undefined };
  }

  get isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  resetRedirectUrl = flow(function* () {
    this.redirectUrl = "/";
  });

  renewToken(cb?: () => void) {
    if (!this.isLoggedIn) {
      return cb && cb();
    }

    this.auth0.checkSession(
      {
        audience: `https://${config.AUTH0_DOMAIN}/api/v2/`,
      },
      (err, result) => {
        if (err) {
          // this.reset()
          console.error(
            `Could not get a new token (${err.error}: ${err.error_description}).`
          );
        } else {
          this.setSession(result);
          console.log(`Successfully renewed auth!`);
        }
        return cb && cb();
      }
    );
  }

  scheduleRenewal() {
    const delay = this.expiresAt - Date.now();
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.tokenRenewalTimeout = undefined;
        this.renewToken();
      }, delay);
    } else {
      // Check access token i local storage
      // If access token is not empty => user is logged in
      // In other case => user is logged out => do not refresh token
      if (this.isLoggedIn) {
        this.renewToken();
      }
    }
  }

  setSession(authResult) {
    if (this.tokenRenewalTimeout) {
      clearTimeout(this.tokenRenewalTimeout);
      this.tokenRenewalTimeout = undefined;
    }

    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    const { idTokenPayload } = authResult;
    const premiumUntil =
      idTokenPayload[`${AUTH0_NAMESPACE}premium_until`] || "0";
    const isSubscribed = !!idTokenPayload[`${AUTH0_NAMESPACE}is_subscribed`];
    const isTrial = !!idTokenPayload[`${AUTH0_NAMESPACE}is_trial`];
    const gymName = idTokenPayload[`${AUTH0_NAMESPACE}gym_name`] || "";
    const franchiseId = idTokenPayload[`${AUTH0_NAMESPACE}franchise_id`] || "";
    const disableMotivationQuotes =
      idTokenPayload[`${AUTH0_NAMESPACE}disable_motivation_quotes`] || "false";
    const isBlocked = !!idTokenPayload[`${AUTH0_NAMESPACE}is_blocked`];
    const limitFreeStation =
      !!idTokenPayload[`${AUTH0_NAMESPACE}limit_free_station`];
    const blockReason = idTokenPayload[`${AUTH0_NAMESPACE}block_reason`] || "";
    const email = idTokenPayload[`${AUTH0_NAMESPACE}email`] || "";
    const emailVerified = !!idTokenPayload[`${AUTH0_NAMESPACE}email_verified`];
    const id = idTokenPayload[`${AUTH0_NAMESPACE}id`] || "";
    const gaiaToken = idTokenPayload[`${AUTH0_NAMESPACE}gaia_token`] || "";
    let explicitFilter =
      idTokenPayload[`${AUTH0_NAMESPACE}explicit_filter`] ||
      DEFAULT_EXPLICIT_FILTER;

    this.id = id;
    this.gymName = gymName;
    this.gaiaToken = gaiaToken;
    this.franchiseId = franchiseId;
    this.isLoggedIn = true;
    this.email = email;
    this.isEmailVerified = emailVerified;
    this.accessToken = authResult.accessToken;
    this.premiumUntil = parseInt(premiumUntil, 10);
    this.expiresAt = parseInt(expiresAt, 10);
    this.isSubscribed = isSubscribed;
    this.isTrial = isTrial;
    this.isBlocked = isBlocked;
    this.limitFreeStation = limitFreeStation;
    this.blockReason = blockReason;
    this.explicitFilter = this.isPremium
      ? explicitFilter
      : DEFAULT_EXPLICIT_FILTER;

    this.scheduleRenewal();
  }

  /*
    Redirects 
    @TODO: move to separate module
  */
  redirectToRecurly(planId: string, newTab = true) {
    const recurlyUrl = `${config.BASE_URL_RECURLY}/subscribe/${planId}/${this.id}/${this.email}`;

    if (newTab) {
      const newWindow = window.open(recurlyUrl, "_blank");
      newWindow.focus();
    } else {
      window.location.href = recurlyUrl;
    }
  }

  redirectToPricingPage(newTab = true) {
    const pricingUrl = `${config.BASE_URL_WEB}/pricing?userid=${this.id}&email=${this.email}`;
    if (newTab) {
      const newWindow = window.open(pricingUrl, "_blank");
      newWindow.focus();
    } else {
      window.location.href = pricingUrl;
    }
  }
}

decorate(UserStore, {
  isLoading: observable,
  accessToken: observable,
  idToken: observable,
  expiresAt: observable,
  id: observable,
  isLoggedIn: [serializable, persist, observable],
  redirectUrl: [serializable, persist, observable],
  gymName: observable,
  gaiaToken: observable,
  email: observable,
  blockReason: observable,
  isEmailVerified: observable,
  isSubscribed: observable,
  isPremium: computed,
  premiumUntil: observable,
  isTrial: observable,
  isAuthenticated: computed,
  franchise: computed,
  observableFranchise: computed,
  franchiseId: observable,
  disableMotivationQuotes: observable,
  explicitFilter: observable,
  setSession: action,
});
