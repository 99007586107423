/* @flow */
import React from 'react'
import { observer, inject } from 'mobx-react'
import { Button } from 'reactstrap'
import { Comment } from '../../components/Icons'
import './Promotion.css'

export type OwnProps = {
}

export type DispatchProps = {
  onOpen: () => void
}

export type Props = OwnProps & DispatchProps

const getStatus = (enabled) => {
  return enabled ? 'enabled' : 'disabled'
}

export default inject((stores) => ({
  audioMessageStore: stores.audioMessageStore,
  playlistStore: stores.playlistStore
}))(
  observer(
    ({
      audioMessageStore,
      playlistStore,
      onOpen
    }: Props) => {
      const { enabledCount } = audioMessageStore
      const { upcomingPromotionalMessages } = playlistStore
      return (
        <section className="promotion">
          <header className="promotion__header">
            <h2 className="promotion__headline">
              <span className="promotion__icon">
                <Comment />
              </span>
              Promotions
            </h2>
            <div className="promotion__customize">
              {
                enabledCount > 0 && (
                  <div className="promotion__status">
                    <span className="promotion__status-content">
                      <span>Enabled</span>
                    </span>
                    <span className="promotion__count"> { enabledCount }</span>
                  </div>
                )
              }
              <Button
                color="primary"
                size="sm"
                className="btn-primary--green"
                onClick={ onOpen }
              >
                <strong>Setup</strong>
              </Button>
            </div>
          </header>
          {
            upcomingPromotionalMessages.length > 0 && (
              <div className="promotion__upcoming">
                <h3 className="promotion__upcoming-headline">Upcoming</h3>
                  <p className="promotion__upcoming-name">
                    "{ upcomingPromotionalMessages[0].title }"
                  </p>
              </div>
            )
          }
          {
            upcomingPromotionalMessages.length === 0 && (
              <div className="promotion__upcoming">
                  <p className="promotion__upcoming-name">
                  Setup audio promotions and help your gym to grow the business.
                  </p>
              </div>
            )
          }
          <p className="mb-0 promotion__upcoming-footer">
            Encourage members to book classes, personal training sessions, refer a friend or to buy products at reception with the audio promotions. <a href="https://gymradio.com/audio-promotions-for-your-gym" rel="noopener noreferrer" target="_blank"> Learn more</a>
          </p>
        </section>
      )
    }
  )
)
