/* @flow */
import React from 'react'
import { observer, inject } from 'mobx-react'
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'
import './Blocked.css'

type ContentMap = {
  [key: 'verification' | 'geolocation']: {
    title: string,
    text: string[]
  }
}

const contentMap: ContentMap = {
  verification: {
    title: <span>Gym email needed</span>,
    text: <div>Thanks a lot for your signup. We really appreciate that you would like to play GYM Radio at your gym. Unfortunately, we have to ask you to use your company email to signup. We don’t allow free emails like gmail, yahoo, outlook etc.<br />Please use your gym’s company email with @gymname domain name.<br />If you believe that this is a mistake, please contact us via support chat and let’s resolve and verify your gym account together.</div>
  },
  geolocation: {
    title: <span>Not yet in your country</span>,
    text: <div>Thanks a lot for your signup. We really appreciate that you would like to play GYM Radio at your gym. Unfortunately, we are not yet available in your country. We are working hard now to be available in the U.S., Australia and Asia as soon as possible. We will let you know when we achieve that.<br />If you believe that this is a mistake, please contact us via support chat and let’s resolve and verify your gym account together.</div>
  },
}

export default inject((stores) => ({
  userStore: stores.userStore
}))(
  observer(({ userStore }) => {
    const { isBlocked, blockReason } = userStore

    const content = contentMap[blockReason] || contentMap['verification']
    return (
      <div>
        <Container className="blocked d-flex justify-content-center align-items-center">
          <Row className="text-center">
            <Col>
              <h1>{ content.title }</h1>
              <p className="blocked__text">
                { content.text }
              </p>
              <Button
                color="primary"
                size="lg"
                className="blocked__btn"
                onClick={ () => userStore.login('', true) }
              >
                Sign up with <strong>company email</strong>
              </Button>
              <p>
                <a href="#" onClick={ () => userStore.login() }>Not your account? Log in with different one.</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  )
)
