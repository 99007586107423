/* @flow */
import {
  observable,
  reaction,
  computed,
  decorate,
  flow
} from 'mobx'
import { serializable } from 'serializr'
import { persist } from 'mobx-persist'

const CACHE_NAME = 'track_cache'

export default class TrackPreloaderStore {
  playlistStore
  userStore
  isLoading = false
  // @TODO: change to state machine
  isOfflineSupported = true
  isOfflineEnabled = true
  preloadPromise

  constructor (userStore, playlistStore) {
    this.playlistStore = playlistStore
    this.userStore = userStore

    if (!('serviceWorker' in navigator)) {
      this.isOfflineSupported = false
      this.isOfflineEnabled = false
    }

    reaction(
      () => ({
        preloadQueue: this.preloadQueue.map((track) => track.id),
        isOfflineEnabled: this.isOfflineEnabled
      }),
      () => {
        if (this.preloadPromise) {
          this.preloadPromise.catch(() => {})
          this.preloadPromise.cancel()
        }
        this.preloadPromise = this.preload()
        return this.preloadPromise
      },
      { delay: 2000 }
    )
  }

  get shouldCacheTracks () {
    return this.isOfflineSupported && this.isOfflineEnabled
  }

  get preloadQueue () {
    return this.playlistStore.tracks
  }

  preload = flow(function * () {
    const { id: userId, isPremium } = this.userStore

    const shouldPreload = userId.length > 0
    if (!shouldPreload) {
      return
    }
    this.isLoading = true

    // Preload 5 tracks for free users and all for premium users
    // Preload 5 tracks if the offline is not enabled
    const MAX_PRELOAD_COUNT = isPremium && this.shouldCacheTracks
      ? Number.MAX_SAFE_INTEGER
      : 5

    const trackUrls = this.preloadQueue.map((track) => track.url)

    const count = Math.min(MAX_PRELOAD_COUNT, this.preloadQueue.length)

    for (let i = 0; i < count; i++) {
      try {
        const track = this.preloadQueue[i]
        if (!track) {
          break
        }
        if (this.shouldCacheTracks) {
          yield this._cacheTrack(trackUrls, track)
        } else {
          yield this._preloadTrack(track)
        }
      } catch (err) {
        console.error(err)
      }
    }
    this.isLoading = false
  })

  async _preloadTrack(track: {}) {
    if (track.preloaded === true) {
      console.log('skipping', track.url)
      return
    }
    return new Promise((resolve) => {
      let audio = new Audio()
      audio.addEventListener('canplaythrough', () => {
        track.preloaded = true
        resolve()
      }, false)
      audio.src = track.url
      //audio.load()
    })
  }

  async _cacheTrack (trackUrls: string[], track: {}) {
    const cache = await window.caches.open(CACHE_NAME)
    const keys = await cache.keys()

    // Remove cached urls that are not in playlist
    keys.forEach((request = {}) => {
      const trackExists = trackUrls.indexOf(request.url) >= 0
      if (!trackExists) {
        cache.delete(request)
      }
    })

    // Skip if url is in already cached
    if (await cache.match(track.url)) {
      track.downloaded = true
      return
    }

    // Cache url
    await cache.add(track.url)

    track.downloaded = true
  }
}

decorate(TrackPreloaderStore, {
  isOfflineEnabled: [serializable, persist, observable],
  isOfflineSupported: observable,
  shouldCacheTracks: computed,
  preloadQueue: computed,
  isLoading: observable
})
