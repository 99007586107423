/* @flow */
import React, { PureComponent } from 'react'

import { Equalizer, SmallPlay } from '../Icons'

import './ChannelItem.css'

export type Channel = {
  image: string,
  name: string,
  genres: string[]
}

export type OwnProps = {
  channel: Channel,
  selected: boolean,
  playing: boolean
}

export type DispatchProps = {
  onSelect: (channel: Channel) => void
}

export type Props = OwnProps

export default class ChannelItem extends PureComponent<Props> {
  render () {
    const {
      channel,
      selected,
      onSelect
    } = this.props
    const {
      image,
      name,
      genres
    } = channel

    let src = image
    const className = selected
      ? 'station__item station__item--active'
      : 'station__item'

    let Icon = SmallPlay
    if (selected) {
      Icon = Equalizer
    }

    return (
      <li className={ className } onClick={ () => { onSelect(channel) } }>
        <article className="station__item-table">
          <div className="station__item-cell">
            <figure className="station__item-image">
              <Icon />
              <img src={ src } alt={ `${name} playlist` } />
            </figure>
          </div>
          <div className="station__item-cell">
            <h3 className="station__item-title">
              { name }
            </h3>

            <ul className="station__item-genres">
              {
                genres.map((genre, i) => {
                  return (
                    <li key={ i } className={ `station__item-genres__item station__item-genres__item--${genre}` }>{ genre }</li>
                  )
                })
              }
            </ul>
          </div>
        </article>
      </li>
    )
  }
}