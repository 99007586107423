export const PRELOAD_TRACKS_COUNT = 20
export const PROMOTION_COUNT = PRELOAD_TRACKS_COUNT
export const TOPSONG_COUNT = PRELOAD_TRACKS_COUNT
export const SONG_COUNT = PRELOAD_TRACKS_COUNT

export const defaultPlaylistStates = {
  start: {
    initial: 'start',
    states: {
      history: {
        type: 'history',
      },
      start: {
        always: [
          {
            target: 'message',
            cond: 'messagesEnabled'
          },
          {
            target: 'motivation',
            cond: 'motivationalMessagesEnabled'
          },
          {
            target: 'promotion',
            cond: 'promotionalMessagesEnabled'
          },
          {
            target: ['#playlist.songs', 'completed']
          }
        ]
      },
      message: {
        meta: {
          type: 'message'
        },
        on: {
          NEXT: [
            {
              target: ['#playlist.songs', 'motivation'],
              cond: 'motivationalMessagesEnabled'
            },
            {
              target: ['#playlist.songs', 'promotion'],
              cond: 'promotionalMessagesEnabled'
            },
            {
              target: ['#playlist.songs', 'completed']
            }
          ]
        }
      },
      motivation: {
        meta: {
          type: 'motivation'
        },
        on: {
          NEXT: [
            {
              target: ['#playlist.songs', 'promotion'],
              cond: 'promotionalMessagesEnabled'
            },
            {
              target: ['#playlist.songs', 'completed']
            }
          ]
        }
      },
      promotion: {
        meta: {
          type: 'promotion'
        },
        on: {
          NEXT: [
            {
              target: ['#playlist.songs', 'completed']
            }
          ]
        }
      },
      completed: {
        type: 'final'
      }
    }
  },
  songs: {
    initial: 'start',
    states: {
      start: {
        always: [
          {
            target: 'topsong',
            cond: 'topSongsEnabled'
          },
          {
            target: 'song1'
          }
        ]
      },
      topsong: {
        meta: {
          type: 'topsong'
        },
        on: {
          NEXT: [
            {
              target: 'song1'
            }
          ]
        }
      },
      song1: {
        meta: {
          type: 'song'
        },
        on: {
          NEXT: [
            {
              target: 'song2'
            }
          ]
        }
      },
      song2: {
        meta: {
          type: 'song'
        },
        on: {
          NEXT: [
            {
              target: 'song3'
            }
          ]
        }
      },
      song3: {
        meta: {
          type: 'song'
        },
        on: {
          NEXT: [
            {
              target: '#playlist.start.history'
            }
          ]
        }
      },
    }
  }
}
