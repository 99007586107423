/* @flow */
import React from "react";
import { observer, inject } from "mobx-react";
import Switch from "rc-switch";
import { Button } from "reactstrap";
import "rc-switch/assets/index.css";
import "./Quotes.css";
import cn from "classnames";
import rateCard from "./rate-card.png"

export type PromotionalMessage = {
  uuid: string,
  artist: string,
  title: string,
  priority: boolean,
  enabled: boolean,
};

export type OwnProps = {
  quotes: PromotionalMessage[],
};

export type DispatchProps = {
  onMotivationalMessagesStatusChanged: (enabled: boolean) => void,
  onPromotionalMessagesStatusChanged: (
    promotionalMessage: PromotionalMessage,
    enabled: boolean
  ) => void,
};

export type Props = OwnProps & DispatchProps;

const onNewAudioMessageRequest = (gymName: string) => {
  window.Intercom(
    "showNewMessage",
    `Request new Audio Promotions for ${gymName}`
  );
};

export default inject((stores) => ({
  userStore: stores.userStore,
}))(
  observer(
    ({
      quotes,
      onMotivationalMessagesStatusChanged,
      onPromotionalMessagesStatusChanged,
      userStore,
    }: Props) => {
      const { gymName } = userStore;

      

      window.HubSpotConversations.on('conversationClosed', payload => {
        console.log(
          `Conversation with id ${
            payload.conversation.conversationId
          } has been closed!`
        );
        if (window.location.search.includes('hs_chatbot=true')) {
          // Remove chatflow parameters from the URL
          const newUrl = window.location.href.replace(/(\?|&)hs_chatbot=true/, '');
          window.history.replaceState({}, document.title, newUrl);
          window.HubSpotConversations.widget.refresh({ openToNewThread: true });
          window.HubSpotConversations.widget.load({ widgetOpen: true });
        }
        
      });
      return (
        <div className="quotes__list">
          <p>Encourage members to book classes, personal training sessions, refer a friend or to buy products at reception with the audio promotions. Our voice-overs are ready to customize the promotions to fit your brand and offerings.</p>
          <p>
            <strong>Increase your sales this month! </strong>
            <a href="https://gymradio.com/audio-promotions-for-your-gym" target="_blank" rel="noopener noreferrer">Read how it works</a>
          </p>
          <ul>
            {
              quotes.map((quote) => {
                const artist = quote.artist || ''
                const title = quote.title || ''
                const uuid = quote.uuid
                const priority = quote.priority || 0
                const enabled = quote.enabled
                return (
                  <li
                    key={ uuid }
                    className="quotes__item"
                  >
                    <div className={
                      cn(
                        'quotes__item__content',
                        {
                          'quotes__item__content--custom': priority > 0
                        }
                      )
                    }>
                      { title }
                    </div>
                    <Switch
                      onChange={ (value) => {
                        onPromotionalMessagesStatusChanged(quote, value)
                      } }
                      checked={ enabled }
                      style={{backgroundColor:enabled?'#ff0046':'grey'}}
                    />
                  </li>
                )
              })
            }
          </ul>

          <img src={rateCard} className="rate-card"/>

          {/* <p>
            Our voice-overs are ready to customize the promotions to fit your
            brand and offerings.
          </p> */}

          {/* <a href={`mailto:support@gymradio.com?subject=${gymName ? gymName : "Custom Audio Request"}`} target="blank" rel="noopener noreferrer"> */}
            <Button
              color="primary"
              className="quotes__button"
              // onClick={() => {
              //   onNewAudioMessageRequest(gymName);
              // }}
              onClick={() => {
                if (window.HubSpotConversations) {
                  window.history.pushState({}, 'hs_chatbot', '?hs_chatbot=true');
                  window.HubSpotConversations.widget.refresh({ openToNewThread: true });
                  window.HubSpotConversations.widget.load({ widgetOpen: true });
                } else {
                  console.error('HubSpot chat widget not available');
                }
              }}
            >
              <strong>Request</strong> custom promotion
            </Button>
          {/* </a> */}

          <h2 className="quotes__motivation-headline">Motivation</h2>
          <ul>
            <li
              key={ 'motivation' }
              className="quotes__item quotes__item--motivation"
            >
              <div className="quotes__item__content">
              We have collected more than 200+ motivational messages and quotes from famous athletes. Keep people motivated and engaged with motivational messages.
              </div>
              <Switch
                onChange={ onMotivationalMessagesStatusChanged }
                checked={ !userStore.disableMotivationQuotes }
                style={{backgroundColor:!userStore.disableMotivationQuotes?'#ff0046':'grey'}}
              />
            </li>
          </ul>
        </div>
      );
    }
  )
);
