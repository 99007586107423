import React, { Component } from 'react';
import './Loading.css'

export default class Loading extends Component {
  render() {
    return (
      <div className="loading_container">
        <div className="loading-wave">
          <div className="loading-rect loading-rect1"></div>
          <div className="loading-rect loading-rect2"></div>
          <div className="loading-rect loading-rect3"></div>
          <div className="loading-rect loading-rect4"></div>
          <div className="loading-rect loading-rect5"></div>
        </div>
      </div>
    )
  }
}
