/* @flow */
import React from 'react'
import './PlayerHeader.css'

export type OwnProps = {
  title: string,
  artist: string
}

export type Props = OwnProps

export default ({ title = 'Awesome music for your gym', artist = 'Loading ...' }: Props) => {
  return (
    <header className="player-header">
      <div className="player-header__song">
        <h2 className="player-header__artist">{ artist }</h2>
        <h3 className="player-header__title">{ title }</h3>
      </div>
    </header>
  )
}
