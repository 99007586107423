/* @flow */
import {
  observable,
  computed,
  decorate,
  flow
} from 'mobx'
import { serializable, primitive } from 'serializr'
import { persist } from 'mobx-persist'
import { isIOS } from '../helpers/mobile-detect'

const DEFAULT_VOLUME = 50
const VOLUME_TRANSITION_TIMEOUT = 10

export default class PlayerStore {
  api
  playlistStore = undefined
  playing = false
  volume = DEFAULT_VOLUME
  animatingVolume = false

  constructor (playlistStore) {
    this.playlistStore = playlistStore
  }

  get normalizedVolume () {
    return this.volume / 100
  }

  get artist () {
    return this.playlistStore.currentTrack.artist || ''
  }

  get title () {
    return this.playlistStore.currentTrack.title || ''
  }

  skip = flow(function * (tags: string[]) {
    const volume = this.volume
    // Do not animate on ios devices
    // It does not work, you can change volume only by hardware buttons
    if (!isIOS()) {
      if (this.playing) {
        yield this.mute()
      }
    }

    yield this.playlistStore.nextTrack(tags)
    if (!isIOS()) {
      this.volume = volume
    }
  })

  mute = flow(function * (animate: boolean = true) {
    if (!animate) {
      this.volume = 0
      return
    }

    let volume = this.volume
    this.volumeChangeInProgress = true
    while (volume > 0) {
      volume--
      yield new Promise((resolve) => {
        setTimeout(() => {
          this.volume = volume
          resolve()
        }, VOLUME_TRANSITION_TIMEOUT)
      })
    }
    this.volumeChangeInProgress = false
  })
}

decorate(PlayerStore, {
  playing: observable,
  volumeChangeInProgress: observable,
  volume: [serializable, persist, observable],
  normalizedVolume: computed,
  title: computed,
  artist: computed
})
