/* @flow */
import axios from 'axios'
import config from './config'
export type SongType = 'message' | 'song' | 'topsong' | 'motivation'

export type Song = {
  uuid: string,
  created: Date,
  enabled: boolean,
  artist: string,
  tags: string[],
  title: string,
  type: SongType,
  key: string,
  url?: string
}

export type Api = {
  listRandom (tags: string[], userId: string, songType: SongType, limit: number, isPremium: boolean): Promise<Song>,
  listLatest (tags: string[], limit: number): Promise<Song>,
  getByUuid (uuid: string, isPremium: boolean): Promise<Song>,
  dislikeSong (userId: string, songId: string): Promise<boolean>,
  fetchAudioMessages (userId: string): Promise<Song[]>,
  fetchRandomAudioMessages (userId: string, isPremium: boolean): Promise<Song[]>,
  enableAudioMessage(uuid: string): Promise<boolean>,
  disableAudioMessage(uuid: string): Promise<boolean>
}

const getProvider = (isPremium: boolean) => {
  return 'primary'
}

export default class ApiImpl implements Api {
  async gaiaReport (token: string, gaiaId: string) {
    if (!token || !gaiaId) {
      return
    }
    const url = `${config.BASE_URL_EVENT_API}/event/gaia`
    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          token,
          trackId: gaiaId
        })
        .then(() => resolve(true))
        .catch(reject)
    })
  }

  async listRandom (tags: string[], userId: string, songType: SongType = 'song', limit: number = 1, isPremium = true) {
    const provider = getProvider(isPremium)
    let url = `${config.BASE_URL_API}/v1/song/list/random?tags=${this.stringifyTags(tags)}&type=${songType}&provider=${provider}&limit=${limit}`
    if (userId) {
      url = `${url}&userId=${userId}`
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const songs = response.data || []
          const mappedSongs = songs.map((song) => this.mapToSong(song))
          resolve(mappedSongs)
        })
        .catch(() => resolve([]))
    })
  }

  async listLatest (tags: string[], limit: number = 1) {
    const url = `${config.BASE_URL_API}/v1/song/list/latest?tags=${this.stringifyTags(tags)}&limit=${limit}`
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const songs = response.data || []
          const mappedSongs = songs.map((song) => this.mapToSong(song))
          resolve(mappedSongs)
        })
        .catch(() => resolve([]))
    })
  }

  async getByUuid (uuid, isPremium = true) {
    const provider = getProvider(isPremium)
    const url = `${config.BASE_URL_API}/v1/song/${uuid}?provider=${provider}`
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const song = response.data
          const mappedSong = this.mapToSong(song)
          resolve(mappedSong)
        })
        .catch(reject)
    })
  }

  async dislikeSong (userId: string, songId: string) {
    const url = `${config.BASE_URL_API}/v1/user/dislike-song`
    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          userId,
          songId
        })
        .then(() => resolve(true))
        .catch(reject)
    })
  }

  async fetchRandomAudioMessages (userId: string, franchiseId: string, limit: number = 1, isPremium = true) {
    const provider = getProvider(isPremium)
    const url = `${config.BASE_URL_API}/v1/audio-message/list/random?userId=${userId}&franchiseId=${franchiseId}&provider=${provider}&limit=${limit}`
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const songs = response.data || []
          const mappedSongs = songs.map((song) => this.mapToSong(song))
          resolve(mappedSongs)
        })
        .catch(() => resolve([]))
    })
  }

  async fetchAudioMessages (userId: string, franchiseId: string) {
    const url = `${config.BASE_URL_API}/v1/audio-message/list?userId=${userId}&franchiseId=${franchiseId}`
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const songs = response.data || []
          const mappedSongs = songs.map((song) => this.mapToSong(song))
          resolve(mappedSongs)
        })
        .catch(() => resolve([]))
    })
  }

  async fetchGymName (userId: string, franchiseId: string, isPremium = true) {
    const provider = getProvider(isPremium)
    const url = `${config.BASE_URL_API}/v1/gym-name?userId=${userId}&franchiseId=${franchiseId}&provider=${provider}`
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const gymNames = response.data || []
          resolve(gymNames)
        })
        .catch(reject)
    })
  }

  async fetchFranchises () {
    const url = `${config.BASE_URL_API}/v1/franchise`
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const franchises = response.data || []
          resolve(franchises)
        })
        .catch(() => resolve([]))
    })
  }

  async fetchFranchiseById (id: string) {
    const url = `${config.BASE_URL_API}/v1/franchise/${id}`
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const franchise = response.data
          resolve(franchise)
        })
        .catch(reject)
    })
  }

  async enableAudioMessage (userId: string, uuid: string) {
    const url = `${config.BASE_URL_API}/v1/audio-message/${uuid}/enable`
    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          userId
        })
        .then(() => resolve(true))
        .catch(reject)
    })
  }

  async disableAudioMessage (userId: string, uuid: string) {
    const url = `${config.BASE_URL_API}/v1/audio-message/${uuid}/disable`
    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          userId
        })
        .then(() => resolve(true))
        .catch(reject)
    })
  }

  stringifyTags = (tags: string[]) => {
    if (!Array.isArray(tags)) {
      return ''
    }
    return [].concat.apply([], tags).join(';') // Flatten results
  }

  mapToSong (song: any): Song {
    const uuid = song.uuid || ''
    const artist = song.artist || ''
    const tags = song.tags || []
    const title = song.title || ''
    const gaiaId = song.gaiaId || ''
    const isrc = song.isrc || ''
    let type = song.type || 'song'

    const url = song.url || ''
    const fullName = `${artist} - ${title}`
    const created = new Date(song.created)
    const key = `${uuid}${Date.now()}${Math.random()}` // randomize uuid => should be possible to add more songs with the same uuid
    const enabled = (typeof song.enabled === 'boolean') ? song.enabled : true

    return {
      uuid,
      created,
      enabled,
      artist,
      gaiaId,
      isrc,
      tags,
      title,
      type,
      url,
      fullName,
      key
    }
  }
}
