/* @flow */
import {
  observable,
  reaction,
  computed,
  decorate,
  flow
} from 'mobx'

export default class AudioMessageStore {
    api
    userStore
    audioMessages = []
    isLoading = true

    constructor (api, userStore) {
      this.api = api
      this.userStore = userStore
      this.loadAudioMessages()
    }

    loadAudioMessages = flow(function * () {
      const { id: userId, franchiseId } = this.userStore
      this.isLoading = true
      const fetchedAudioMessages = yield this.api.fetchAudioMessages(userId, franchiseId)
      fetchedAudioMessages
        .forEach((json) => this.updateAudioMessageFromServer(json))

      // Sort
      this.audioMessages.replace(this
        .audioMessages
        .slice()
        .sort((x, y) => {
          return y.priority - x.priority || x.title.localeCompare(y.title)
        })
      )

      this.isLoading = false
    })

    get enabledCount () {
      return this.audioMessages.filter((item) => {
        return item.enabled
      })
      .length
    }

    updateAudioMessageFromServer (json) {
      let audioMessage = this.audioMessages.find(audioMessage => audioMessage.uuid === json.uuid)
      if (!audioMessage) {
        audioMessage = new AudioMessage(this, json.uuid)
        this.audioMessages.push(audioMessage)
      }
      audioMessage.updateFromJson(json)
  }
}

decorate(AudioMessageStore, {
  audioMessages: observable,
  isLoading: observable,
  enabledCount: computed
})

export class AudioMessage {
  uuid = ''
  enabled = false
  artist = ''
  title = ''
  type = ''
  priority = 0

  store = undefined

  constructor (store, uuid) {
    this.store = store
    this.uuid = uuid
  }

  setEnabled = flow(function * (enable: boolean) {
    const { id: userId } = this.store.userStore
    if (userId && userId.length > 0) {
      if (enable) {
        this.store.api.enableAudioMessage(userId, this.uuid)
      } else {
        this.store.api.disableAudioMessage(userId, this.uuid)
      }
      this.enabled = enable
    }
  })

  updateFromJson (json) {
    this.uuid = json.uuid || ''
    this.enabled = json.enabled
    this.artist = json.artist || ''
    this.title = json.title || ''
    this.type = json.type || ''

    // Priority
    const tags = json.tags || []

    this.priority = tags
      .indexOf('businessmessages') >= 0
      ? 0
      : 1000
  }

  get asJson () {
    return {
      uuid: this.uuid,
      enabled: this.enabled,
      artist: this.artist,
      title: this.title,
      type: this.type,
      priority: this.priority
    }
  }
}

decorate(AudioMessage, {
  enabled: observable,
  asJson: computed
})
