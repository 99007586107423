/* @flow */
import {
  observable,
  computed,
  decorate
} from 'mobx'
import { serializable } from 'serializr'
import { persist } from 'mobx-persist'

type Station = {
  id: string,
  name: string,
  image: string,
  tags: string[],
  genres: string[]
}

const premiumStations: Station[] = [
  {
    id: 'new-releases',
    name: 'New releases',
    image: 'assets/images/channels/New Releases.png',
    tags: ['newreleases'],
    genres: ["new music", "pop", "rock", "urban", "dance"]
  },
  {
    id: 'gym',
    name: 'Gym',
    image: 'assets/images/channels/Gym.png',
    tags: ['gym'],
    genres: ["house", "electro", "dance"]
  },
  {
    id: 'fitness',
    name: 'Cardio',
    image: 'assets/images/channels/Cardio.png',
    tags: ['fitness'],
    genres: ["pop", "dance", "top charts"]
  },
  {
    id: 'cardio',
    name: 'Throwbacks',
    image: 'assets/images/channels/Throwbacks.png',
    tags: ['cardio'],
    genres: ["old skool", "classics", "dance anthems"]
  },
  {
    id: 'fight',
    name: 'Fight Club',
    image: 'assets/images/channels/Fight Club.png',
    tags: ['fight'],
    genres: ['rock']
  },
  {
    id: 'hardcore',
    name: 'Resistence',
    image: 'assets/images/channels/Resistance.png',
    tags: ['hardcore'],
    genres: ["urban", "rock"]
  }
]

const defaultPremiumStation: Station = {
  id: 'all',
  name: 'All music',
  image: 'assets/images/channels/All Music.png',
  tags: ['cardio', 'fitness', 'gym', 'fight'],
  genres: ["house", "pop", "rock", "urban"]
}

const defaultFreeStation: Station = {
  id: 'free',
  name: 'Free Trial',
  image: 'assets/images/channels/All Music.png',
  tags: ['cardio', 'fitness', 'gym', 'fight'],
  genres: ['ads', 'cardio', 'fitness', 'gym']
}

export const DEFAULT_PREMIUM_CHANNEL = 'all'
export const DEFAULT_FREE_CHANNEL = 'free'

export default class StationStore {
  userStore
  selectedStationId
  isLoading = true

  constructor (userStore) {
    this.userStore = userStore
  }

  get stations () {
    const { isPremium, franchise } = this.userStore

    const defaultStation = isPremium
      ? defaultPremiumStation
      : defaultFreeStation

    const franchiseStations = (franchise && franchise.stations) || []

    return [
      ...franchiseStations,
      defaultStation,
      ...premiumStations
    ]
  }

  get selectedStation () {
    const { isPremium } = this.userStore
    if (!isPremium) {
      return defaultFreeStation
    }

    if (!this.selectedStationId) {
      return defaultPremiumStation
    }

    const selectedStation = this
      .stations
      .find((station) => station.id === this.selectedStationId)

    return selectedStation || defaultPremiumStation
  }

  updateStationFromServer (json) {
    let station = this.stations.find(station => station.id === json.id)
    if (!station) {
      station = new StationItem(this, json.uuid)
      this.stations.push(station)
    }
    station.updateFromJson(json)
  }
}

decorate(StationStore, {
  stations: computed,
  selectedStationId: [serializable, persist, observable],
  selectedStation: computed,
  isLoading: observable
})

export class StationItem {
  id = ''
  name = ''
  image = ''
  tags = []
  genres = []

  store = undefined

  constructor (store, id) {
    this.store = store
    this.id = id
  }

  updateFromJson (json) {
    this.id = json.id || ''
    this.name = json.name || ''
    this.image = json.image || ''
    this.tags = json.tags || []
    this.genres = json.genres || []
  }

  get asJson () {
    return {
      id: this.id,
      name: this.name,
      image: this.image,
      tags: this.tags,
      genres: this.genres
    }
  }
}

decorate(StationItem, {
  asJson: computed
})