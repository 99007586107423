/* @flow */
import config from './config'

export type SongPlayedEvent = {
  event: 'song_played',
  songName: string,
  songUuid: string,
  selectedPlaylistId: string
}

export type SongPausedEvent = {
  event: 'song_paused',
  songName: string,
  songUuid: string,
  selectedPlaylistId: string
}

export type SongRemovedEvent = {
  event: 'song_removed',
  songName: string,
  songUuid: string,
  selectedPlaylistId: string
}

export type SongDislikedEvent = {
  event: 'song_disliked',
  songName: string,
  songUuid: string,
  selectedPlaylistId: string
}

export type SongSkippedEvent = {
  event: 'song_skipped',
  songName: string,
  songUuid: string,
  selectedPlaylistId: string
}

export type PlaybackErrorEvent = {
  event: 'playback_error',
  songName: string,
  songUuid: string,
  selectedPlaylistId: string,
  errorStatus: number,
  errorText: string
}

export type PlaylistSelectedEvent = {
  event: 'playlist_selected',
  playlistId: string
}

export type AudioMessageSelectedEvent = {
  event: 'audio_message_selected',
  audioMessageName: string,
  audioMessageUuid: string
}

export type RecentlyAddedSelectedEvent = {
  event: 'recenty_added_selected',
  songName: string,
  songUuid: string
}

export type LoggedInEvent = {
  event: 'logged_in'
}

export type EmailVerifiedEvent = {
  event: 'email_verified'
}

export type ProActivatedEvent = {
  event: 'pro_activated'
}

export type SubscriptionActivatedEvent = {
  event: 'pro_activated'
}

export type LoginPopupShowedEvent = {
  event: 'login_popup_showed'
}

export type FreeSlotPopupShowedEvent = {
  event: 'free_slot_popup_showed'
}

export type ProPopupShowedEvent = {
  event: 'pro_popup_showed'
}

export type GetProButtonClickedEvent = {
  event: 'get_pro_button_clicked'
}

export type ExplicitFilterChangedEvent = {
  event: 'explicit_filter_changed',
  explicitFilterValue: string
}

export type MotivationalMessagesStatusChangedEvent = {
  event: 'motivational_messages_status_changed',
  enabled: boolean
}

export type PromotionalMessagesStatusChangedEvent = {
  event: 'promotional_messages_status_changed',
  promotionalMessageTitle: string,
  promotionalMessageUuid: string,
  enabled: boolean
}

export type AudioPromotionsPopupShowed = {
  event: 'audio_promotions_popup_showed'
}

export type TrackingEvents =
  SongPlayedEvent |
  SongPausedEvent |
  SongRemovedEvent |
  SongDislikedEvent |
  SongSkippedEvent |
  PlaybackErrorEvent |
  PlaylistSelectedEvent |
  AudioMessageSelectedEvent |
  RecentlyAddedSelectedEvent |
  LoggedInEvent |
  EmailVerifiedEvent |
  ProActivatedEvent |
  LoginPopupShowedEvent |
  FreeSlotPopupShowedEvent |
  ProPopupShowedEvent |
  GetProButtonClickedEvent |
  ExplicitFilterChangedEvent |
  MotivationalMessagesStatusChangedEvent |
  PromotionalMessagesStatusChangedEvent |
  AudioPromotionsPopupShowed

export type TrackingProvider = 'mixpanel' | 'intercom'

export type Analytics = {
  init (userId?: string, email?: string): Promise<boolean>,
  track (obj: TrackingEvents, providers: TrackingProvider[]): Promise<boolean>
}

export default class AnalyticsImpl implements Analytics {
  init (userId?: string, franchiseId?: string, email?: string) {
    // Mixpanel
    if (window.mixpanel && config.MIXPANEL_TOKEN) {
      window.mixpanel.init(config.MIXPANEL_TOKEN)
      // First seen
      window.mixpanel.register_once({
        firstSeen: new Date() 
      })

      // Identify user
      if (userId) {
        window.mixpanel.identify(userId)
      }
    }

    // Intercom
    if (window.Intercom && config.INTERCOM_APP_ID) {
      let settings = {
        app_id: config.INTERCOM_APP_ID
      }
      if (userId) {
        settings['user_id'] = userId
      }
      if (email) {
        settings['email'] = email
      }
      if (franchiseId) {
        settings['franchise_id'] = franchiseId
      }
  
      window.Intercom('boot', settings)
    }

    return true
  }

  track (obj: TrackingEvents, providers: TrackingProvider[] = ['mixpanel' , 'intercom']): Promise<boolean> {
    config.MIXPANEL_TOKEN && this._trackMixpanel(obj)
    config.INTERCOM_APP_ID && this._trackIntercom(obj)
    this._trackFBPixel(obj)
    this._trackGoogleTagManager(obj)
    return true
  }

  _trackMixpanel (obj: TrackingEvents): Promise<boolean> {
    const { event, ...metadata } = obj
    setTimeout(() => { // track in the next event loop tick
      window.mixpanel && window.mixpanel.track(event, metadata)
    }, 0)
  }

  _trackIntercom (obj: TrackingEvents): Promise<boolean> {
    const { event, ...metadata } = obj
    setTimeout(() => { // track in the next event loop tick
      window.Intercom && window.Intercom('trackEvent', event, metadata)
    }, 0)
  }

  _trackFBPixel (obj: TrackingEvents): Promise<boolean> {
    const { event, ...metadata } = obj
    setTimeout(() => { // track in the next event loop tick
      window.fbq && window.fbq('trackCustom', event, metadata)
    }, 0)
  }

  _trackGoogleTagManager (obj: TrackingEvents): Promise<boolean> {
    const { event, ...metadata } = obj
    setTimeout(() => { // track in the next event loop tick
      window.dataLayer && window.dataLayer.push({ [event]: metadata })
    }, 0)
  }
}
