/* @flow */
import React from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import './EmailVerification.css'

export default ({ email }) => {
  return (
    <div className="email_verification">
      <Container>
        <Row>
          <Col>
            We have sent an email with a verification link to { email }. <strong>Click the verification link to verify your account.</strong>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
