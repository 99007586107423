/* @flow */
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import FlipMove from 'react-flip-move'

import UpcomingItem from '../UpcomingItem'
import PlaceholderItems from '../PlaceholderItems'
import { TextRow, Circle, Line } from '../Placeholders'

import './Upcoming.css'

export type OwnProps = {
  maxCount: number
}

export type Props = {
  onRemoveTrack: (uuid: string) => void,
  onNextTrack: (uuid: string) => void,
  onDislikeTrack: (uuid: string) => void,
  onDownloadedClick: () => void
}

class Upcoming extends Component<Props> {
  render () {
    const { playlistStore, onRemoveTrack, onDislikeTrack, onNextTrack, onDownloadedClick } = this.props
    const { tracks, isLoading, maxTrackCount} = playlistStore

    return (
      <div className="upcoming">
        { isLoading && (
            <PlaceholderItems count={ maxTrackCount } />
          )
        }
        <FlipMove
          className="upcoming__list"
          duration={300}
          easing="ease-out"
          enterAnimation="fade"
          leaveAnimation={ null }
          typeName="ul"
        >
          {
            tracks
              .filter((track, index) => (
                !(track.type === 'gymname' && index !== 0)
              ))
              .slice(0, maxTrackCount)
              .map((track, index) => {
                const iconType = index === 0 ? 'playing' : track.type
                return (
                  <UpcomingItem
                    id={ index }
                    key={ track.id }
                    track={ track }
                    iconType={ iconType }
                    onRemoveTrack={ onRemoveTrack }
                    onDownloadedClick={ onDownloadedClick }
                    onDislikeTrack={ onDislikeTrack }
                    onNextTrack={ onNextTrack }
                  />
                )
              })
          }
        </FlipMove>
      </div>
    )
  }
}

export default inject((stores) => ({
  playlistStore: stores.playlistStore
}))(
  observer(Upcoming)
)