/* @flow */
import React, { PureComponent } from 'react'

export type OwnProps = {
  autoPlay: boolean,
  src: string
}

export type DispatchProps = {
  onEnded: () => void,
  onPlay: () => void,
  onPause: () => void,
  onError?: (status: number, text: string) => void
}

export type Props = OwnProps & DispatchProps

export default class Audio extends PureComponent<Props> {
  audioEl

  constructor (props) {
    super(props)
    this.setAudioRef = this.setAudioRef.bind(this)
    this.onError = this.onError.bind(this)
  }

  setAudioRef (el) {
    this.audioEl = el
  }

  onError (event: Event) {
    const { onError, src } = this.props
    const http = new XMLHttpRequest()
    http.open('GET', src)
    http.onreadystatechange = function () {
      if (
        this.readyState === this.HEADERS_RECEIVED ||
        this.readyState === this.LOADING
      ) {
        if (this.status >= 300 || this.status < 100) {
          onError && onError(this.status, this.statusText)
        }
        http.abort()
      }
    }
    http.send()
  }

  render () {
    return (
      <audio
        autoPlay={ this.props.autoPlay }
        src={ this.props.src }
        ref={ this.setAudioRef }
        onError={ this.onError }
        onEnded={ this.props.onEnded.bind(this) }
        onPlay={ this.props.onPlay.bind(this) }
        onPause={ this.props.onPause.bind(this) }
      >
        <p>Your browser does not support the <code>audio</code> element.</p>
      </audio>
    )
  }
}
