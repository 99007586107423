/* @flow */
import React from 'react'
import Responsive from 'react-responsive'

const tabletMin = 768
const desktopMin = 992

const mobileMax = tabletMin - 1
const tabletMax = desktopMin - 1

const Mobile = (props) => <Responsive { ...props } maxWidth={ mobileMax } />

const MobileAndTablet = (props) => <Responsive { ...props } maxWidth={ tabletMax } />

const Tablet = (props) => <Responsive { ...props } minWidth={ tabletMin } maxWidth={ tabletMax } />
const TabletAndUp = (props) => <Responsive { ...props } minWidth={ tabletMin } />

const Desktop = (props) => <Responsive { ...props } minWidth={ desktopMin } />

export {
  Mobile,
  MobileAndTablet,
  Tablet,
  TabletAndUp,
  Desktop
}
