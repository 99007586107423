/* @flow */
import { observer, inject } from 'mobx-react'
import React, { Component } from 'react'
import { Tooltip } from 'reactstrap'
import cx from 'classnames'

import {
  SmallNote,
  SmallEqualizer,
  SmallComment,
  Download,
  DislikeSmall,
  NextSmall,
  Close,
  SmallStar
} from '../Icons'

import './UpcomingItem.css'

export type OwnProps = {
  track: {
    artist: string,
    title: string,
    key: string,
    uuid: string,
    loaded: boolean,
    type: 'song' | 'topsong' | 'promotion',
    iconType: 'song' | 'topsong' | 'promotion' | 'playing'
  }
}

export type DispatchProps = {
  onRemoveTrack: (track: OwnProps) => void,
  onNextTrack: (track: OwnProps) => void,
  onDislikeTrack: (track: OwnProps) => void
}

export type Props = OwnProps & DispatchProps

class UpcomingItem extends Component<Props, { isOpen: boolean }> {
  state = {
    isOpen: false
  }

  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.onRemoveTrack = this.onRemoveTrack.bind(this)
    this.onDislikeTrack = this.onDislikeTrack.bind(this)
    this.onNextTrack = this.onNextTrack.bind(this)
    this.onDownloadedClick = this.onDownloadedClick.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  onRemoveTrack () {
    const { onRemoveTrack, track } = this.props
    onRemoveTrack(track)
  }

  onDislikeTrack () {
    const { onDislikeTrack, track } = this.props
    onDislikeTrack(track)
  }

  onNextTrack () {
    const { onNextTrack, track } = this.props
    const { uuid } = track
    onNextTrack(uuid)
  }

  onDownloadedClick () {
    const { onDownloadedClick } = this.props
    onDownloadedClick()
  }

  render () {
    const { track, iconType, id, userStore, trackPreloaderStore } = this.props
    const { isOfflineEnabled } = trackPreloaderStore
    const { isPremium } = userStore
    const { type, artist, title, downloaded, viewType } = track

    let Icon = SmallNote
    if (
      iconType === 'promotion' ||
      iconType === 'message' ||
      iconType === 'motivation'
    ) {
      Icon = SmallComment
    }
    if (iconType === 'playing') {
      Icon = SmallEqualizer
    }
    if (iconType === 'topsong') {
      Icon = SmallStar
    }

    let i = 0

    const tooltipMessage = this._getTooltipMessage(downloaded, isPremium, isOfflineEnabled)
    return (
      <li
        className={ `upcoming-item__container upcoming-item__container--${type} upcoming-item__container--${iconType} upcoming-item__container--${viewType}` }
      >
        <div className={ `upcoming-item upcoming-item--${type} upcoming-item--${viewType}` } title={ `${artist} - ${title}` } >
          <div className={ `upcoming-item__image upcoming-item__image--${type} upcoming-item__image--${viewType}` }>
            <Icon />
          </div>
          <div className="upcoming-item__title">
            <strong>
              { artist.toUpperCase() }
            </strong> - { title }
          </div>
          <i
            className="icon icon--dislike"
            onClick={ this.onDislikeTrack }
          >
            <DislikeSmall />
          </i>
          {
            iconType !== 'playing' &&
            (
              <i
                className="icon icon--close"
                onClick={ this.onRemoveTrack }
              >
                <Close />
              </i>
            )
          }
          {
            iconType === 'playing' &&
            (
              <i
                className="icon icon--next"
                onClick={ this.onNextTrack }
              >
                <NextSmall />
              </i>
            )
          }
          { /*
          <i
            className={ cx( 'icon--download', { 'icon--download-ready': downloaded && isPremium && isOfflineEnabled }) }
            id={ `icon--download-${id}`}
            onClick={ this.onDownloadedClick }
          >
            <Download />
          </i>
          { tooltipMessage &&
            (
              <Tooltip
                placement="top"
                isOpen={ this.state.isOpen }
                toggle={ this.toggle }
                target={ `icon--download-${id}` }
              >
                { tooltipMessage }
              </Tooltip>
            )
          }
          */ }
        </div>
      </li>
    )
  }

  _getTooltipMessage (loaded: boolean, isPremium: boolean, offlineEnabled: boolean) {
    if (loaded && isPremium && offlineEnabled) {
      return (
        <span>Song is available offline.</span>
      )
    }
    if (!isPremium) {
      return (
        <span>
          Song is not available offline.<br />
          Upgrade to PRO to use this feature.
        </span>
      )
    }
    if (!offlineEnabled) {
      return
    }
    return (
      <span>
        Song is being downloaded.
      </span>
    )
  }
}

export default inject((stores) => ({
  userStore: stores.userStore,
  trackPreloaderStore: stores.trackPreloaderStore
}))(
  observer(UpcomingItem)
)
