/* @flow */
import React, { PureComponent } from 'react'

import ChannelItem from '../ChannelItem'
import { Note } from '../Icons'
import './Channels.css'

export type Channel = {
  image: string,
  name: string,
  genres: string[]
}

export type OwnProps = {
  channels: Channel[],
  activeChannelId: string,
  playing: boolean
}

export type DispatchProps = {
  onSelect: (channel: Channel) => void
}

export type Props = OwnProps & DispatchProps

export default class Channels extends PureComponent<Props> {
  render () {
    const {
      channels,
      activeChannelId,
      playing,
      onSelect
    } = this.props

    return (
      <div className="station">
        <h2 className="station__title" ><Note /> Stations</h2>
        <ul className="station__list">
        {
          channels.map((channel) => {
            const selected = activeChannelId === channel.id
            return (
              <ChannelItem
                key={ channel.id }
                channel={ channel }
                playing={ playing && selected }
                selected={ selected }
                onSelect={ onSelect }
              />
            )
          })
        }
        </ul>
      </div>
    )
  }
}
