/* @flow */
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import Blocked from '../../components/Blocked'

class App extends Component {
  render () {
    const { isBlocked, children } = this.props

    const Component = isBlocked
      ? <Blocked />
      : children

    return Component
  }
}

export default inject((stores) => ({
  isBlocked: stores.userStore.isBlocked
}))(
  observer(App)
)
